import React from "react";
import styled from "styled-components";

const links = [{ text: "Request account deletion", href: "/accounts/delete" }];

const Page = styled.div`
  min-height: 100vh;
  background: #000212;
  color: white;
`;

const Container = styled.div`
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  text-align: left;
`;

const Main = styled.main`
  padding-top: 24vh;
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  @media screen and (max-width: 545px) {
    flex-flow: column nowrap;
  }
`;

const ListItem = styled.li`
  margin-right: 22px;
  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 545px) {
    margin-right: 0px;
    margin-bottom: 18px;
  }
`;

const Link = styled.a`
  color: red;
`;

const Heading = styled.h1`
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 55px;
  font-family: EuclidFlex-Semibold-WebTrial;
`;

const AccountsPage = () => {
  return (
    <Page>
      <Container>
        <Main>
          <Heading>Accounts</Heading>
          <List>
            {links.map((link, i) => (
              <ListItem key={i}>
                <Link target="_blank" href={link.href}>
                  {link.text}
                </Link>
              </ListItem>
            ))}
          </List>
        </Main>
      </Container>
    </Page>
  );
};

export default AccountsPage;
